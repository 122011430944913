import React from "react";

const Header = ({ bot, handleRefresh, primary, handleToggleWidth, view }) => {

  const language = localStorage.getItem('language') || 'en';

  return (
    <div className={`flex items-center justify-between pt-[8px] py-3 sm:py-3  border-b-2 px-3 border-gray-200`} style={{ backgroundColor: primary}}>
      <div className="relative flex items-center space-x-4">
        <img src={bot.image} alt="Brainstormer Logo" className="w-10 sm:w-16 h-10 sm:h-16 rounded-full" />
        <div className="flex flex-col leading-tight">
          <div className="text-lg sm:text-xl leading-6">
            <span className="text-white mr-3">{bot.name}</span>
          </div>
          <span className={`text-xs sm:text-[14px] text-white mr-3 text-${language == 'ar' ? 'right': 'left'}`}>{bot.description}</span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="cursor-pointer flex" onClick={handleRefresh}>
          <img src="/assets/images/trash-icon.svg" width="20" height="20" alt="Clear Icon" />
        </div>
        {view && <div className="cursor-pointer flex ml-5" onClick={handleToggleWidth}>
          <img src={view === 'wide' ? "/assets/images/narow-icon.svg" : "/assets/images/wide-icon.svg"} width="20" height="20" alt="Refresh Icon" />
        </div>}
      </div>
    </div>
  )
}

export default Header;